import React, { useEffect, useState } from "react";
import { getData } from "../../webService/webService";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Iconify from "../iconify";
import ClientBasicandAdvance from "./ClientBasicandAdvance";
import { useDispatch } from "react-redux";
import { setUpcomingClient } from "../../redux/slices/UpcomingSlice";

function Upcomingclient() {
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const scheme_session = sessionStorage.getItem("scheme");
  const [SelectedId, setSelectedId] = useState("");
  const [SpecificClientData, setSpecificClientData] = useState([]);
  const [Values, setValues] = useState([]);
  console.log(Values, "Data");

  const GetCount = () => {
    const params = {
      scheme: scheme_session === "HRA" ? "HRA" : "CB",
    };
    getData(`masters/audit/upcoming/details`, params, accessToken)
      .then((res) => {
        console.log(res, "data123");
        setValues(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  console.log("SpecificClientData", SpecificClientData);
  useEffect(() => {
    GetCount();
  }, []);
  useEffect(() => {
    if (SelectedId) {
      if (Values !== null) {
        const client = Values.find((client) => client._id === SelectedId);
        if (client) {
          setSpecificClientData(client);
          dispatch(setUpcomingClient(client));
          handleClickOpen();
        }
      }
    }
  }, [SelectedId]);
  function updateExpiryDate(inputDate) {
    // Parse the input date string into a Date object
    const currentDate = new Date(inputDate);

    // Add one year to the current date
    const expiryDate = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    // Return the updated expiry date in ISO format
    return expiryDate;
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId("");
  };
  function formatExpiryDate(inputDate) {
    // Assuming updateExpiryDate is a function that takes a date and updates it accordingly
    const expiryDate = updateExpiryDate(inputDate);

    const year = expiryDate.getFullYear();
    const month = String(expiryDate.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
    const day = String(expiryDate.getDate()).padStart(2, "0"); // Add leading zero if needed

    const formattedExpiryDate = `${year}-${month}-${day}`;
    return formattedExpiryDate;
  }
  return (
    <>
      <Dialog
        fullScreen={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">View Client</DialogTitle>
        <DialogContent>
          <ClientBasicandAdvance />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Card style={{ height: "100%" }}>
        <h5 className="ps-3 mt-3">Upcoming Client</h5>

        <TableContainer sx={{ minWidth: 800 }} style={{ height: "400px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="cl-green text-dark">Client Id</TableCell>
                <TableCell className="cl-green text-dark">
                  Company Name
                </TableCell>
                <TableCell className="cl-green text-dark">
                  Technical Main Code
                </TableCell>
                <TableCell className="cl-green text-dark">
                  Technical Sub Code
                </TableCell>
                <TableCell className="cl-green text-dark">Scheme</TableCell>
                <TableCell className="cl-green text-dark">Location</TableCell>
                <TableCell className="cl-green text-dark text-center">
                  Current Status
                </TableCell>
                <TableCell className="cl-green text-dark text-center">
                  Expiry Date
                </TableCell>
                <TableCell className="cl-green text-dark text-center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Values && Values.length !== 0 ? (
                Values.map((row) => {
                  const {
                    _id,
                    Client_Basic_Details,
                    Client_IMS_Details,
                    Client_FSMS_Details,
                  } = row;
                  return (
                    <TableRow key={_id}>
                      <TableCell>{Client_Basic_Details[0]?.clientId}</TableCell>
                      <TableCell>
                        {Client_Basic_Details[0]?.companyName}
                      </TableCell>
                      <TableCell>
                        {Client_Basic_Details[0]?.technicalCode.mainCode}
                      </TableCell>
                      <TableCell>
                        {Client_Basic_Details[0]?.technicalCode.subCode}
                      </TableCell>
                      <TableCell>
                        {Client_Basic_Details[0]?.standardsSchemeType}
                      </TableCell>
                      <TableCell>
                        {Client_Basic_Details[0]?.state} /{" "}
                        {Client_Basic_Details[0]?.country}
                      </TableCell>
                      {/* <TableCell>
                        {Client_Basic_Details[0].auditStatesAndStatuses.map(

                          (data, index) => (
                            <div key={index}>
                              {data.isCurrentState === "true" && (
                                <span>{data.status}</span>
                              )}
                            </div>
                          )
                        )}
                      </TableCell> */}
                      <TableCell>
                        {(() => {
                          const statuses =
                            Client_Basic_Details[0].auditStatesAndStatuses;
                          for (let i = statuses?.length - 1; i >= 0; i--) {
                            if (statuses[i].isCurrentState === true) {
                              return (
                                <span key={statuses[i]._id}>
                                  {statuses[i].status}
                                </span>
                              );
                            }
                          }
                          return null;
                        })()}
                      </TableCell>
                      <TableCell>
                        {(() => {
                          const statuses =
                            Client_Basic_Details[0].auditStatesAndStatuses;
                          for (let i = statuses?.length - 1; i >= 0; i--) {
                            if (statuses[i].isCurrentState === true) {
                              return (
                                <span key={statuses[i]._id}>
                                  {formatExpiryDate(statuses[i].date)}
                                </span>
                              );
                            }
                          }
                          return null;
                        })()}
                      </TableCell>

                      {/* <TableCell>
                        {Client_Basic_Details[0].auditStatesAndStatuses.map(

                          (data, index) => {
                            // Check if isCurrentState is true
                            if (data.isCurrentState === "true") {
                              const expiryDate = updateExpiryDate(data.date);
                              const year = expiryDate.getFullYear();
                              const month = String(
                                expiryDate.getMonth() + 1
                              ).padStart(2, "0"); // Add leading zero if needed
                              const day = String(expiryDate.getDate()).padStart(
                                2,
                                "0"
                              ); // Add leading zero if needed
                              const formattedExpiryDate = `${year}-${month}-${day}`;
                              return (
                                <div key={index}>{formattedExpiryDate}</div>
                              );
                            }
                          }
                        )}
                      </TableCell> */}

                      <TableCell align="center">
                        <IconButton
                          className="cl-white text-black"
                          onClick={() => {
                            setSelectedId(_id);
                          }}                            >
                          <Iconify
                            icon={"raphael:view"}
                           />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow align="center">
                  <h6 className="my-3">No Data</h6>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default Upcomingclient;
