import { Helmet } from "react-helmet-async";
import React, { useEffect, useState } from "react";
// @mui
import { Button, Container } from "@mui/material";
import ReactbigCalendar from "src/components/Calendar/ReactbigCalendar";
import Activity_details from "src/components/Auditor/Activity_details";
import Corrective from "src/components/Auditor/Corrective";
import Documents from "src/components/Auditor/Documents";
import Event_tracking from "src/components/Auditor/Event_tracking";
import HRA_Checklist from "src/components/Auditor/CheckList";
import HRA_NC from "src/components/Auditor/NC";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Iconify from "src/components/iconify";
import { getData, patchData, postData } from "src/webService/webService";
import { setAdvance, setBasic } from "src/redux/slices/SpecificClient";
import { useDispatch, useSelector } from "react-redux";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import ClientStatusTrackerBasic from "../../CommonFunction/clientStatusTrackerBasic";
import EVENT_TRACKING_ROUTES from "../../API_Routes/EventTracking/EventTracking_Routers";
import API_URL from "../../utils/url";
import AuditTable from "../../components/AuditorLog/Logs";
import EMAIL_ROUTES from "../../API_Routes/Email/Email_Routers";
import CommonEmail from "../../Config/templates/html/CommonEmailTem";
import { fetchAllReviewer } from "../../CommonFunction/GetAllReviewer";
import { getFormattedIndiaTime } from "../../utils/timeutils";
// ----------------------------------------------------------------------
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AuditorCalendar({
  auditId,
  clientId,
  auditorRoleData,
}) {
  console.log(
    auditId,
    "auditId",
    clientId,
    "clientId",
    auditorRoleData,
    "auditorRoleData"
  );
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const ClientStatusTracker = useSelector(
    (state) => state?.SpecificClient.Basic
  );
  const ReviewerNameList = useSelector(
    (state) => state?.ReviewerList.ReviewerNameList
  );
  const scheme_session = sessionStorage.getItem("scheme");
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const USER_ID = sessionStorage.getItem("UserId");
  const accessToken = sessionStorage.getItem("Token");
  const [showmoredata, setshowmoredata] = useState(false);
  const [ClientBasic, setClientBasic] = useState(null);
  const [ClientAdvance, setClientAdvance] = useState(null);
  const [AuditId, setAuditId] = useState();
  const [AuditLogData, setAuditLogData] = useState(null);
  const [auditData, setauditData] = useState();
  const [auditorRole, setauditorRole] = useState();
  const [reviewerId, setReviewerId] = useState();
  console.log(auditData && auditData.isSpecialAudit, "isSpecialAudit");
  const GetauditData = async () => {
    try {
      const res = await getData(
        AUDITROUTES.SCHEDULEPATCH(AuditId),
        null,
        accessToken
      );
      setauditData(res.data);
      setReviewerId(res.data.reviewerId);
      console.log("Audit document response:", res);
    } catch (error) {
      console.error("Audit document error:", error);
    }
  };

  const handleScheduleClick = async (auditId, clientId, auditorRoleData) => {
    // Handle the click event of a scheduled date here
    console.log("Clicked on schedule with Audit ID:", auditId);
    sessionStorage.setItem("selectedAuditId", auditId);
    console.log(clientId, "clientIds");
    console.log(auditorRoleData, "auditorRoleData");
    setAuditId(auditId);
    setauditorRole(auditorRoleData);
    // Set showmoredata to true
    setshowmoredata(true);

    const basicResponse = await getData(
      `${CLIENTROUTES.GETSPECIFICUSERBASIC}${clientId}/basic`,
      null,
      accessToken
    );
    const basicData = basicResponse.data;
    setClientBasic(basicData);
    dispatch(setBasic(basicData));

    // Fetch Advance details using the Basic details id
    const advanceResponse = await getData(
      `${CLIENTROUTES.GETSPECIFICUSERADVANCE}${clientId}/advanced`,
      null,
      accessToken
    );
    const advanceData = advanceResponse.data;
    setClientAdvance(advanceData);
    dispatch(setAdvance(advanceData));
  };
  const BackToCalender = () => {
    setshowmoredata(false);
    setAuditId();
  };
  const getPersonNameById = (id, PlannerList) => {
    if (id === null) {
      return null;
    } else {
      const person = PlannerList.find(
        (plannerPerson) => plannerPerson._id === id
      );
      if (person) {
        return { email: person.email };
      } else {
        return null;
      }
    }
  };
  const Auditcompleted = async () => {
    const formattedDate = getFormattedIndiaTime();
    if (auditData.reportStatus.name === "Audit Draft Report Sent") {
      const params = {
        reportStatus: {
          name: "Audit report completed",
          date: formattedDate,
        },
        activityStep: "4",
      };
      const endpoint = patchData(
        `${AUDITROUTES.SCHEDULEPATCH(AuditId)}`,
        params,
        accessToken,
        dispatch
      );
      if (auditData.specialAuditIsForAuditId) {
        // ClientStatusTrackerBasic(
        //   ClientStatusTracker._id,
        //   "0",
        //   `${auditData.auditType} Special Audit Completed`,
        //   ClientStatusTracker.clientStatusTracker
        // );
        const newStatusTracking = {
          year: 0,
          status: `${auditData.auditType} Special Audit Completed`,
          date: formattedDate,
        };
        const updatedClientStatusTracker = [
          ...ClientStatusTracker.clientStatusTracker,
          newStatusTracking,
        ];
        const params = {
          clientStatusTracker: updatedClientStatusTracker,
        };
        await patchData(
          `clients/client/${ClientStatusTracker._id}/basic`,
          params,
          accessToken,
          dispatch
        );
        await postData(
          EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientStatusTracker._id),
          {
            description: `${auditData.auditType} Special Audit Completed`,
            performedBy: `${sessionStorage.getItem(
              "firstName"
            )} ${sessionStorage.getItem("lastName")}`,
          },
          accessToken,
          dispatch
        );
        if (reviewerId) {
          const ReviewerMail = getPersonNameById(reviewerId, ReviewerNameList);
          //Mail to Reviewer
          const HTML_DATA = {
            ClientName: ClientBasic.companyName,
            ContactPerson: ClientBasic.contactPerson,
            ClientID: ClientBasic.clientId,
            AuditType: auditData.auditType,
            Message: "The above client special audit execution completed",
          };
          await postData(
            EMAIL_ROUTES.SEND_EMAIL_TEXT,
            {
              to: ReviewerMail.email,
              subject: "Special Audit Completed",
              html: CommonEmail(HTML_DATA),
            },
            accessToken,
            dispatch
          );
        }
      } else {
        // ClientStatusTrackerBasic(
        //   ClientStatusTracker._id,
        //   "0",
        //   `${auditData.auditType} Audit Completed`,
        //   ClientStatusTracker.clientStatusTracker
        // );

        const newStatusTracking = {
          year: 0,
          status: `${auditData.auditType} Audit Completed`,
          date: formattedDate,
        };
        const updatedClientStatusTracker = [
          ...ClientStatusTracker.clientStatusTracker,
          newStatusTracking,
        ];
        const params = {
          clientStatusTracker: updatedClientStatusTracker,
        };
        await patchData(
          `clients/client/${ClientStatusTracker._id}/basic`,
          params,
          accessToken,
          dispatch
        );

        await postData(
          EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientStatusTracker._id),
          {
            description: `${auditData.auditType} Audit Completed`,
            performedBy: `${sessionStorage.getItem(
              "firstName"
            )} ${sessionStorage.getItem("lastName")}`,
          },
          accessToken,
          dispatch
        );
        //Mail to CSO
        const HTML_DATA = {
          ClientName: ClientBasic.companyName,
          ContactPerson: ClientBasic.contactPerson,
          ClientID: ClientBasic.clientId,
          AuditType: auditData.auditType,
          Message:
            "The above client audit execution completed and ready for the report review",
        };
        await postData(
          EMAIL_ROUTES.SEND_EMAIL_TEXT,
          {
            to: `cso@caussatec.com`,
            subject: "Audit Completed",
            html: CommonEmail(HTML_DATA),
          },
          accessToken,
          dispatch
        );
        //Mail to Account
        const HTML_DATA_1 = {
          ClientName: ClientBasic.companyName,
          ContactPerson: ClientBasic.contactPerson,
          ClientID: ClientBasic.clientId,
          AuditType: auditData.auditType,
          Message: "The above client audit execution completed",
        };
        await postData(
          EMAIL_ROUTES.SEND_EMAIL_TEXT,
          {
            to: `accounts@caussatec.com`,
            subject: `${auditData.auditType} - Audit Completed`,
            html: CommonEmail(HTML_DATA_1),
          },
          accessToken,
          dispatch
        );
      }
      BackToCalender();
    } else if (
      auditData.reportStatus.name ===
        "STAGE_1 Technical Report Review Need Revision" ||
      auditData.reportStatus.name ===
        "STAGE_2 Technical Report Review Need Revision" ||
      auditData.reportStatus.name ===
        "SA_1 Technical Report Review Need Revision" ||
      auditData.reportStatus.name ===
        "SA_2 Technical Report Review Need Revision" ||
      auditData.reportStatus.name ===
        "RECERTIFICATION Technical Report Review Need Revision" ||
      (auditData.reportStatus.name ===
        "TRANSFER_AUDIT_IN_RA Technical Report Review Need Revision") |
        (auditData.reportStatus.name ===
          "Technical Report Review Need Revision")
    ) {
      const params = {
        reportStatus: {
          name: "Need Revision Completed",
          date: formattedDate,
        },
        activityStep: "7",
      };
      const endpoint = patchData(
        `${AUDITROUTES.SCHEDULEPATCH(AuditId)}`,
        params,
        accessToken,
        dispatch
      );
      // Event updates
      await postData(
        EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientStatusTracker._id),
        {
          description: auditData?.specialAuditIsForAuditId
            ? `Special Audit - ${auditData.auditType} Need Revision Completed`
            : `${auditData.auditType} Need Revision Completed`,
          performedBy: `${sessionStorage.getItem(
            "firstName"
          )} ${sessionStorage.getItem("lastName")}`,
        },
        accessToken,
        dispatch
      );
      //Mail to Reviewer
      if (reviewerId) {
        const ReviewerMail = getPersonNameById(reviewerId, ReviewerNameList);
        const HTML_DATA_1 = {
          ClientName: ClientBasic.companyName,
          ContactPerson: ClientBasic.contactPerson,
          ClientID: ClientBasic.clientId,
          AuditType: auditData.auditType,
          Message: "The above client Audit Need Revision Completed",
        };
        await postData(
          EMAIL_ROUTES.SEND_EMAIL_TEXT,
          {
            to: ReviewerMail.email,
            subject: `${auditData.auditType} - Audit Need Revision Completed`,
            html: CommonEmail(HTML_DATA_1),
          },
          accessToken,
          dispatch
        );
      }
      BackToCalender();
    } else {
      toast.error("Already Submited Audit", {
        position: toast.POSITION.TOP_RIGHT,
      });
      BackToCalender();
    }
  };
  const GetAuditLog = async () => {
    const AuditLog = await getData(
      `users/auditor/${USER_ID}/logs`,
      null,
      accessToken
    );
    setAuditLogData(AuditLog.data[0]);
  };
  useEffect(() => {
    GetAuditLog();
    fetchAllReviewer(dispatch, accessToken);
  }, []);
  useEffect(() => {
    if (AuditId) {
      GetauditData();
    }
  }, [AuditId]);
  useEffect(() => {
    if (isAuthenticated === "Admin") {
      GetauditData();
      handleScheduleClick(auditId, clientId, auditorRoleData);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <Container maxWidth="xl">
        {!showmoredata && isAuthenticated !== "Admin" ? (
          <>
            {AuditLogData && (
              <AuditTable apiResponse={AuditLogData} auditorId={USER_ID} />
            )}

            <ReactbigCalendar
              setshowmoredata={setshowmoredata}
              onScheduleClick={handleScheduleClick}
            />
          </>
        ) : (
          <div className="client-list-border pt-0 pb-5 overflow-hidden">
            <div
              className="d-flex position-relative align-items-center  justify-content-between py-3"
              style={{ backgroundColor: "#68B4E6" }}
            >
              <div className="d-flex px-4 col-7">
                <Iconify className="me-3 mt-1" icon={"bxs:file"} />
                <h4>
                  Certification Activity
                  {auditData?.isSpecialAudit && (
                    <span className="special-audit">Special Audit</span>
                  )}
                </h4>
              </div>
              <div className="d-flex col-4 ">
                <Iconify className="me-3 mt-1" icon={"bxs:file"} />
                <h4>Company Name:</h4>
                <h5 className="mx-3">{ClientBasic?.companyName}</h5>
              </div>
              {isAuthenticated !== "Admin" && (
                <div className="d-flex justify-content-end align-items-center">
                  <Iconify
                    style={{ cursor: "pointer" }}
                    icon={"zondicons:close-solid"}
                    onClick={BackToCalender}
                  />
                </div>
              )}
              <hr className="bold" />
            </div>
            <div className="col-12 px-4" style={{ backgroundColor: "#68B4E6" }}>
              <p>
                <span className="fw-bold">Audit Type</span> :{" "}
                {auditData && auditData.auditType}
              </p>
              <p>
                <span className="fw-bold">Audit Step</span> :{" "}
                {auditData && auditData.activityStep}
              </p>
              <p className="mb-0">
                <span className="fw-bold">Audit Status</span> :{" "}
                {auditData && auditData.reportStatus.name}
              </p>
            </div>
            {auditorRole && auditorRole === "Lead Auditor" ? (
              <div
                className="d-flex justify-content-end pb-2"
                style={{ backgroundColor: "#68B4E6" }}
              >
                {/* {
                Planner Assigns Auditor
              } */}
                <Button
                  variant="contained"
                  className={
                    auditData?.reportStatus.name ===
                      "Audit Draft Report Sent" ||
                    auditData?.reportStatus.name ===
                      "STAGE_1 Technical Report Review Need Revision" ||
                    auditData?.reportStatus.name ===
                      "STAGE_2 Technical Report Review Need Revision" ||
                    auditData?.reportStatus.name ===
                      "SA_1 Technical Report Review Need Revision" ||
                    auditData?.reportStatus.name ===
                      "SA_2 Technical Report Review Need Revision" ||
                    auditData?.reportStatus.name ===
                      "RECERTIFICATION Technical Report Review Need Revision" ||
                    auditData?.reportStatus.name ===
                      "TRANSFER_AUDIT_IN_RA Technical Report Review Need Revision" ||
                    auditData?.reportStatus.name ===
                      "Technical Report Review Need Revision"
                      ? "cl-green text-white "
                      : "btn-green-disable "
                  }
                  disabled={
                    auditData?.reportStatus.name ===
                      "Audit Draft Report Sent" ||
                    auditData?.reportStatus.name ===
                      "Technical Report Review Need Revision" ||
                    auditData?.reportStatus.name ===
                      "STAGE_1 Technical Report Review Need Revision"
                      ? false
                      : true
                  }
                  onClick={Auditcompleted}
                >
                  Finish The Audit
                </Button>
              </div>
            ) : (
              ""
            )}

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  className="tab-list"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Activity Details" {...a11yProps(0)} />
                  <Tab label="Corrective Actions" {...a11yProps(1)} />
                  <Tab label="Documents" {...a11yProps(2)} />
                  <Tab label="Event Tracking" {...a11yProps(3)} />

                  {scheme_session !== "CB" && (
                    <>
                      <Tab label="Check list" {...a11yProps(4)} />
                      <Tab label="NC" {...a11yProps(5)} />
                    </>
                  )}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Activity_details
                  AuditId={AuditId}
                  ClientBasic={ClientBasic}
                  ClientAdvance={ClientAdvance}
                  auditData={auditData}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Corrective
                  AuditId={AuditId}
                  setshowmoredata={setshowmoredata}
                  BackToCalender={BackToCalender}
                  ClientBasic={ClientBasic}
                  auditData={auditData}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Documents
                  AuditId={AuditId}
                  ClientBasic={ClientBasic}
                  auditData={auditData}
                  BackToCalender={BackToCalender}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Event_tracking ClientBasic={ClientBasic} />
              </CustomTabPanel>

              {scheme_session !== "CB" && (
                <>
                  <CustomTabPanel value={value} index={4}>
                    <HRA_Checklist />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={5}>
                    <HRA_NC />
                  </CustomTabPanel>
                </>
              )}
            </Box>
          </div>
        )}
      </Container>
    </>
  );
}
