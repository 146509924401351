import React, { useState, Button, useEffect } from "react";
import "../../components/TrainingCertifications/TrainingCertifications.css";
import USERSROUTES from "src/API_Routes/User/User_Routers";
import { FileUpload, getData, patchData } from "src/webService/webService";
import { TextField } from "@mui/material";
import Iconify from "../iconify/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FILEROUTES from "src/API_Routes/File/File_Router";
import SERVER_URL from "../../utils/serverURL";
import { useDispatch } from "react-redux";

const TrainingCertifications = ({ usercurrentid }) => {
  const dispatch = useDispatch();
  const [UserId, setuserId] = useState();
  console.log(usercurrentid, "usercurrentid");
  const iduser = sessionStorage.getItem("UserId");
  const accessToken = sessionStorage.getItem("Token");
  // Check if usercurrentid is not null or undefined, then assign UserId
  // const UserId =
  //   usercurrentid !== null ? usercurrentid : sessionStorage.getItem("UserId");
  console.log(UserId, "UserId");
  const [fieldName, setFieldName] = useState();
  const [uploadFileShow, setUploadfileShow] = useState([]);
  const [editable, setEditable] = useState(false);
  const [file1, setFile] = useState();
  useEffect(() => {
    if (usercurrentid) {
      setuserId(usercurrentid);
    } else {
      setuserId(iduser);
    }
  }, []);
  const handleFileChange = async (e) => {
    if (fieldName) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await FileUpload(
          FILEROUTES.UPLOAD + UserId,
          formData,
          dispatch
        );
        console.log("File upload successful:", response);
        setFile(response.data);
        setFile((prevState) => ({
          ...prevState,
          ...response.data,
          fieldName: fieldName,
        }));
        setFieldName("");
      } catch (error) {
        console.error("File upload error:", error);
      }
    } else {
      toast.error("Field Name Is Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const UserDateUpdate = () => {
    const params = {
      trainingAndCertificateFileObjects: uploadFileShow,
    };
    patchData(USERSROUTES.EDITUSER + UserId, params, accessToken, dispatch)
      .then((res) => {
        console.log(res, "res");
        EditTrainingData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetUserBasicData = () => {
    getData(USERSROUTES.USERID + UserId, null, accessToken)
      .then((res) => {
        setUploadfileShow(res.data.trainingAndCertificateFileObjects);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  useEffect(() => {
    if (UserId) {
      GetUserBasicData();
    }
  }, [UserId]);
  // Function to convert bytes to kilobytes
  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2); // Fixed to 2 decimal places
  };
  const EditTrainingData = () => {
    setEditable(!editable);
  };
  useEffect(() => {
    if (file1) {
      console.log("file1", file1);
      console.log("uploadFileShow", uploadFileShow);
      setUploadfileShow((prevUploadFileShow) => {
        return [...prevUploadFileShow, file1];
      });
      setFile();
    }
  }, [file1]);

  const removeDocument = (data) => {
    const updatedUploadFileShow = uploadFileShow.filter(
      (item, index) => index !== data
    );
    setUploadfileShow(updatedUploadFileShow);
  };

  return (
    <>
      <ToastContainer />
      <div className="TrainingCertifications-border p-5">
        <div className="px-2 add-title">
          <span className="">Training & Certification</span>
        </div>
        {editable ? (
          ""
        ) : (
          <div className="d-flex justify-content-end">
            <button
              variant="contained"
              style={{ borderRadius: "50%", height: "40px", width: "40px" }}
              className="btn-green text-white border-0 p-2 d-flex justify-content-center align-items-center"
              onClick={EditTrainingData}
            >
              <Iconify icon={"eva:edit-fill"} />
            </button>
          </div>
        )}
        {editable ? (
          <div className="d-flex col-12 align-items-center">
            <div className="col-2">
              {" "}
              <TextField
                name="fieldName"
                label="Certificate Name"
                value={fieldName}
                size="small"
                onChange={(e) => setFieldName(e.target.value)}
                required
              />
            </div>
            <div className="col-4 d-flex">
              <div className="inputs ms-4 mb-0 col-4">
                <label
                  htmlFor="file-input-1"
                  className="d-flex justify-content-between w-100 py-1"
                >
                  <span>
                    {" "}
                    <Iconify icon={"bi:upload"} />
                  </span>
                  Upload File
                </label>
                <input
                  id="file-input-1"
                  type="file"
                  className="Upload-file"
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="mt-3">
          {uploadFileShow.length === 0 ? (
            <p>No File Found</p>
          ) : (
            uploadFileShow.map((data, index) => (
              <div key={index} className="mb-3 col-4">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold mb-0">{data.fieldName} </p>
                  {editable && (
                    <p className="fw-bold mb-0">
                      <Iconify
                        className="delete-button"
                        onClick={() => removeDocument(index)}
                        icon={"streamline:delete-1-solid"}
                      />
                    </p>
                  )}
                </div>
                <a
                  rel="noreferrer"
                  href={`${SERVER_URL}/${data.uri}`}
                  target="_blank"
                >
                  {/* Display the filename by splitting the URI */}
                  {data.uri && data.uri.split("/").slice(-1)[0]}
                </a>{" "}
                <span className="fw-bold">- {bytesToKB(data.size)} Kb</span>
              </div>
            ))
          )}
        </div>
        {editable ? (
          <div className="d-flex justify-content-end">
            <button
              variant="contained"
              style={{ borderRadius: "5px" }}
              className="btn-orange text-white border-0 px-5 fw-bold py-2"
              onClick={EditTrainingData}
            >
              Cancel
            </button>
            <button
              variant="contained"
              style={{ borderRadius: "5px" }}
              className="btn-green text-white border-0 px-5 fw-bold  py-2"
              onClick={UserDateUpdate}
            >
              Submit
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default TrainingCertifications;
