const SERVER_ENV = "dev";

let CSO_EMAIL;

if( SERVER_ENV === 'dev' ){
    CSO_EMAIL = 'cso@test.com';
}

if( SERVER_ENV === 'local' ){
    CSO_EMAIL = 'cso@test.com';
}

if( SERVER_ENV === 'prod' ){
    CSO_EMAIL = 'cso@caussatec.com';
}

export {
    CSO_EMAIL
}
