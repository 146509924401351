const SERVER_ENV = "prod";

let API_URL;

if( SERVER_ENV === 'prod' ){
    API_URL = 'https://api.caussatec.com/api/v1/';
}
if( SERVER_ENV === 'dev' ){
    API_URL = '';
}
if( SERVER_ENV === 'local' ){
    API_URL = 'http://localhost:5000/api/v1/';
}

export default API_URL;