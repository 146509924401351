import { createSlice } from "@reduxjs/toolkit";

const PlannerSlice = createSlice({
  name: "Planner",
  initialState: {
    ClientBasicandAdvance: "",
    AuditList: null,
    totalItems: 0
  },
  reducers: {
    setClientBasicandAdvance: (state, action) => {
      state.ClientBasicandAdvance = action.payload;
    },
    setAuditList: (state, action) => {
      state.AuditList = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
  },
});

export const { setClientBasicandAdvance, setAuditList, setTotalItems } = PlannerSlice.actions;
export default PlannerSlice.reducer;
